import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import configuration from '../../../config'
import { OUTBRAIN_SCRIPT_ID } from '../../../constants/scripts'
import { useScript } from '../../../hook/useScript'

export interface IOutbrain {
  desktopId: string
  permalink: string
}

export function Outbrain({ desktopId, permalink }: IOutbrain): JSX.Element {
  const isAmp = useAmp()
  const { asPath } = useRouter()
  const ampUrl = `${configuration.domain}${asPath}`.replace(/.html\/$/, '.html')

  useScript({ id: OUTBRAIN_SCRIPT_ID })

  return isAmp ? (
    <amp-ad
      data-block-on-consent="_till_responded"
      width="100"
      height="100"
      type="outbrain"
      layout="responsive"
      data-widgetIds={desktopId}
      data-htmlURL={permalink}
      data-ampURL={ampUrl}
    ></amp-ad>
  ) : (
    <>
      <div className="Outbrain__Wrapper">
        <div className="OUTBRAIN" data-src={permalink} data-widget-id={desktopId} />
      </div>
      <style jsx>{`
        .Outbrain__Wrapper {
          margin-bottom: 70px;
          width: 100%;
        }
      `}</style>
    </>
  )
}
